import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
import ai from "../../Assets/Projects/ai.png";
import bill from "../../Assets/Projects/bill.png";
import bullet from "../../Assets/Projects/bulletjournal.png";
import message from "../../Assets/Projects/message.png";
import llm from "../../Assets/Projects/llm.png";
import rook from "../../Assets/Projects/rook.png";
import suicide from "../../Assets/Projects/suicidedetection.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Projects</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Suicide Ideation Detection"
              description="Trained logistic regression, LSTM, and transformer models on online social media post data to detect suicide ideation."
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={llm}
              isBlog={false}
              title="Custom LLM Training"
              description="An LLM model that allows users to drag and drop their own text files to train the model on and then generate sentences that mimic the style of the file."
              ghLink="https://github.com/mattrwang/drag-n-drop-train"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rook}
              isBlog={false}
              title="Rook Jumping Maze"
              description="A maze generating game that creates optimized mazes using simulated annealing and hill descent algorithms. Finds the optimal solution using BFS and A* algorithms."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={message}
              isBlog={false}
              title="Message Board"
              description="A universal message board that users around the globe can post to. Supports account creation, deleting posts, and filters profanity."
              ghLink="https://github.com/mattrwang/Message-Board"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bill}
              isBlog={false}
              title="FairShare"
              description="A mobile app that fairly divides a restaurant bill among groups of people. Automatically calculates tax, tip, and allows for splitting of items."
              ghLink="https://github.com/Oasis-NEU/f23-group04"
              // demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ai}
              isBlog={false}
              title="Islands of Hex"
              description="A version of the Islands of Hex game, where players aim to create as many islands of their color as possible. Comes with two artificial intelligence bots that use alpha-beta pruning and minimax to play against."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bullet}
              isBlog={false}
              title="Bullet Journal"
              description="A personal bullet journal to record tasks and events throughout the week. Password-protected and comes with customizable themes and layouts."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Plant AI"
              description=""
              //ghLink="https://github.com/soumyajit4419/Plant_AI"
              //demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
